import { Box, Card, CardContent, Grid, Skeleton, Stack } from "@mui/material";
import React from "react";
import { cardStyle } from "../../../styles/card";

function InstaCardSkeleton() {
    const style = cardStyle;
  return ( <Card
    sx={{
        ...style.instacardWithSwitch,
        padding:2.2
    }}
  >
    <Stack direction={"row"} spacing={2} >
        <Skeleton  variant="rounded" height={32} width={32}/>
        <Skeleton variant="text" width="70%"  height={18}/>
        <Box sx={{paddingTop:.5}}>
        <Skeleton  variant="rounded" height={25} width={50} sx={{borderRadius:"20px", }}/>
        </Box>
        
    </Stack>

  </Card>);
}

export default InstaCardSkeleton;
