import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { CacheProvider } from "@emotion/react";
import {
	ThemeProvider,
	CssBaseline,
	StyledEngineProvider,
} from "@mui/material";

import createEmotionCache from "../utility/createEmotionCache";
import lightTheme from "../styles/theme/lightTheme";
import "../styles/globals.css";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import { SessionProvider } from "next-auth/react";
import "slick-carousel/slick/slick-theme.css";
import { app, analytics } from "../lib/firebase";
import "react-toastify/dist/ReactToastify.css";
import "../styles/slide.css";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { CurationLayout } from "../components/Layout/Curation";
import localforage from "localforage";
import PushNotificationLayout from "../components/PushNotificationLayout";
import Router from "next/router";
import { LoadingSpinner } from "../components/LoadingSpinner/LoadingSpinner";
import AddToHomeScreen from "a2hs.js";
import blackShare from "assets/icon/shareblack.svg";
import RefreshTokenHandler from "../components/refreshTokenHandler";

import { RouterScrollProvider } from "@moxy/next-router-scroll";
import CurationListProvider from "../context/CurationContext";
import ScrollRestorationProvider from "../context/ScrollRestorationContext";

import HomeScreenSkeleton from "../components/Skeletons/Screens/HomeScreenSkeleton";
import PlayoffDetailsSkeleton from "../components/Skeletons/Screens/PlayoffDetailsSkeleton";
import CurationListingSkeleton from "../components/Skeletons/Screens/CurationListingSkeleton";
import CurationDetailsSkeleton from "../components/Skeletons/Screens/CurationDetailsSkeleton";
import DiscoverScreenSkeleton from "../components/Skeletons/Screens/DiscoverScreenSkeleton";
import CurateScreenSkeleton from "../components/Skeletons/Screens/CurateScreenSkeleton";
import FavoriteScreenSkeleton from "../components/Skeletons/Screens/FavoriteScreenSkeleton";
import CurationTitleSkeleton from "../components/Skeletons/Screens/CurationTitleSkeleton";
import PlayoffProductListingSkeleton from "../components/Skeletons/Screens/PlayoffProductListingSkeleton";
import SuccessScreenSkeleton from "../components/Skeletons/Screens/SuccessScreenSkeleton";
import UserScreenSkeleton from "../components/Skeletons/Screens/UserScreenSkeleton";
import Layout from "../layout/Layout";
import Skeleton from "../components/LoadingSpinner/Skeleton";
import ScrollProvider from "../context/ScrollContext";
import CurationListingWithVideoSkeleton from "../components/Skeletons/Screens/CurationListingWithVideoSkeleton";

const clientSideEmotionCache = createEmotionCache();

const MyApp = (props) => {
	const {
		Component,
		emotionCache = clientSideEmotionCache,
		pageProps,
	} = props;

	// useScrollRestoration(router);
	const isBack = useRef(false);
	const [toBack,setToBack]=useState(false)
	const [isLoading, setIsLoading] = useState(false);
	const [dynamicLodingScreen, setDynamicLodingScreen] = useState(
		<LoadingSpinner />
	);
	const [isiOS, setIsiOS] = useState(false);

	useEffect(() => {
		if (
			typeof window !== "undefined" &&
			"serviceWorker" in navigator &&
			window.workbox !== undefined
		) {
			if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
				setIsiOS(/iPhone|iPad|iPod/i.test(navigator.userAgent));
			}
			if (navigator.userAgent.indexOf("Instagram") !== -1) {
				setIsiOS(true);
			}

			const wb = window.workbox;
			//   window.addEventListener('installed', event => {
			// 	if(!event.isUpdate){
			// 		console.log('====================================');
			// 		console.log("inside isUpdate");
			// 		console.log('====================================');
			// 		localStorage.removeItem()
			// 	}

			// 	console.log(`Event ${event.type} is triggered.`)
			// 	console.log(event)
			//   })
			//   window.screen.orientation.lock("portrait-primary")
			//   console.log(window.screen.orientation.type);
			// add event listeners to handle any of PWA lifecycle event
			// https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-window.Workbox#events
			wb.addEventListener("installed", (event) => {
				// if(!event.isUpdate){
				// console.log('====================================');
				// console.log("inside isUpdate first time");
				// console.log('====================================');
				// localStorage.clear()
				// }
				//	localStorage.removeItem()
				console.log(`Event ${event.type} is triggered.`);
				console.log(event);
			});

			wb.addEventListener("controlling", (event) => {
				console.log(`Event ${event.type} is triggered.`);
				console.log(event);
			});

			wb.addEventListener("activated", (event) => {
				navigator.serviceWorker
					.getRegistrations("/firebase-messaging-sw.js")
					.then(function (registrations) {
						registrations.forEach(function (v) {
							if (
								v.active.scriptURL.includes(
									"firebase-messaging-sw.js"
								)
							) {
								v.update();
							}

							// v.update();
						});
					});
				console.log(`Event ${event.type} is triggered.`);
				console.log(event);
			});

			// A common UX pattern for progressive web apps is to show a banner when a service worker has updated and waiting to install.
			// NOTE: MUST set skipWaiting to false in next.config.js pwa object
			// https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
			//   const promptNewVersionAvailable = event => {
			// 	// `event.wasWaitingBeforeRegister` will be false if this is the first time the updated service worker is waiting.
			// 	// When `event.wasWaitingBeforeRegister` is true, a previously updated service worker is still waiting.
			// 	// You may want to customize the UI prompt accordingly.
			// 	if (confirm('A newer version of this web app is available, reload to update?')) {
			// 	  wb.addEventListener('controlling', event => {
			// 		window.location.reload()
			// 	  })

			// 	  // Send a message to the waiting service worker, instructing it to activate.
			// 	  wb.messageSkipWaiting()
			// 	} else {
			// 	  console.log(
			// 		'User rejected to reload the web app, keep using old version. New version will be automatically load when user open the app next time.'
			// 	  )
			// 	}
			//   }

			//   wb.addEventListener('waiting', promptNewVersionAvailable)

			// ISSUE - this is not working as expected, why?
			// I could only make message event listenser work when I manually add this listenser into sw.js file
			wb.addEventListener("message", (event) => {
				console.log(`Event ${event.type} is triggered.`);
				console.log(event);
			});

			/*
		  wb.addEventListener('redundant', event => {
			console.log(`Event ${event.type} is triggered.`)
			console.log(event)
		  })
		  wb.addEventListener('externalinstalled', event => {
			console.log(`Event ${event.type} is triggered.`)
			console.log(event)
		  })
		  wb.addEventListener('externalactivated', event => {
			console.log(`Event ${event.type} is triggered.`)
			console.log(event)
		  })
		  */

			// never forget to call register as auto register is turned off in next.config.js
			wb.register();
			if (process.env.NODE_ENV === "production") {
				analytics;
			}
			// logEvent(window.location.pathname);
			// }
		}
		const auth = getAuth();
		onAuthStateChanged(auth, (user) => {
			if (user) {
				// localStorage.setItem('set_categories',true)
				const uid = user.uid;
			} else {
				localforage.setItem("set_categories", false);
				signInAnonymously(auth)
					.then(() => {
						console.log("Yes");
					})
					.catch((error) => {
						const errorCode = error.code;
						const errorMessage = error.message;
						console.log("====================================");
						console.log(error.message);
						console.log("====================================");
						// ...
					});
				// User is signed out
			}
		});
		// setTimeout(() => {
		// 	AddToHomeScreen({
		// 		logoImage: `<svg width="70" height="70" viewBox="0 0 70 70" fill="none" style="border-radius:12px" xmlns="http://www.w3.org/2000/svg">
		// 				<rect width="70" height="70" fill="#7E0CF5" />
		// 				<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 15.386719 37.3125 C 14.890625 37.597656 14.351562 37.710938 13.800781 37.710938 C 12.167969 37.710938 11.011719 36.5625 11.011719 35 C 11.011719 33.402344 12.167969 32.289062 13.808594 32.289062 C 14.636719 32.289062 15.332031 32.566406 15.9375 33.171875 L 16.839844 32.289062 C 16.113281 31.464844 15.03125 31.035156 13.746094 31.035156 C 11.332031 31.035156 9.570312 32.699219 9.570312 35 C 9.570312 37.300781 11.332031 38.964844 13.722656 38.964844 C 14.8125 38.964844 15.9375 38.632812 16.738281 37.984375 L 16.738281 34.910156 L 15.386719 34.910156 Z M 15.386719 37.3125 "/>
		// 					<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 28.1875 38.855469 L 26.414062 36.320312 C 27.460938 35.914062 28.054688 35.054688 28.054688 33.886719 C 28.054688 32.179688 26.800781 31.144531 24.773438 31.144531 L 21.601562 31.144531 L 21.601562 38.855469 L 23.035156 38.855469 L 23.035156 36.609375 L 24.773438 36.609375 C 24.875 36.609375 24.972656 36.609375 25.070312 36.597656 L 26.644531 38.855469 Z M 26.613281 33.886719 C 26.613281 34.855469 25.964844 35.429688 24.707031 35.429688 L 23.035156 35.429688 L 23.035156 32.355469 L 24.707031 32.355469 C 25.964844 32.355469 26.613281 32.917969 26.613281 33.886719 Z M 26.613281 33.886719 "/>
		// 					<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 38.402344 38.855469 L 39.898438 38.855469 L 36.421875 31.144531 L 35.011719 31.144531 L 31.542969 38.855469 L 33.015625 38.855469 L 33.777344 37.070312 L 37.632812 37.070312 Z M 34.261719 35.945312 L 35.703125 32.578125 L 37.15625 35.945312 Z M 34.261719 35.945312 "/>
		// 					<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 47.027344 31.144531 L 43.855469 31.144531 L 43.855469 38.855469 L 45.285156 38.855469 L 45.285156 36.628906 L 47.027344 36.628906 C 49.054688 36.628906 50.308594 35.582031 50.308594 33.886719 C 50.308594 32.179688 49.054688 31.144531 47.027344 31.144531 Z M 46.960938 35.417969 L 45.285156 35.417969 L 45.285156 32.355469 L 46.960938 32.355469 C 48.214844 32.355469 48.867188 32.917969 48.867188 33.886719 C 48.867188 34.855469 48.214844 35.417969 46.960938 35.417969 Z M 46.960938 35.417969 "/>
		// 					<path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 56.144531 37.652344 L 56.144531 35.527344 L 59.867188 35.527344 L 59.867188 34.351562 L 56.144531 34.351562 L 56.144531 32.347656 L 60.339844 32.347656 L 60.339844 31.144531 L 54.710938 31.144531 L 54.710938 38.855469 L 60.492188 38.855469 L 60.492188 37.652344 Z M 56.144531 37.652344 "/>
		// 			</svg>`,
		// 		htmlContent: `To install <strong>Grape app</strong> on your iOS: tap <img src="${blackShare}" width="14"  height="14" ></img>  icon & tap ”Add to Home Screen”.
		// 		Option not showing for you? Copy & paste this link into Safari and try again.`,
		// 	});
		// }, 1000);
	}, []);

	useEffect(() => {
		Router.events.on("routeChangeStart", (url) => {
			setIsLoading(true);
			if (url == "/") {
				setDynamicLodingScreen(<HomeScreenSkeleton />);
			} else if (
				/^\/playoff\/([a-zA-Z0-9-]+)\/products$/.test(url) ||
				/^\/product-category\/\d+$/.test(url)
			) {
				setDynamicLodingScreen(<PlayoffProductListingSkeleton />);
			}else if (/^\/playoff\/([a-zA-Z0-9-]+)\/curations$/.test(url)) {
				setDynamicLodingScreen(<CurationListingSkeleton />);
			} else if (/^\/playoff\/[a-zA-Z0-9-]+$/.test(url)) {
				setDynamicLodingScreen(<PlayoffDetailsSkeleton />);
			} else if (url == "/curation/add") {
				setDynamicLodingScreen(<CurationTitleSkeleton />);
			} else if (/^\/curation\/([a-zA-Z0-9-]+)\/success$/.test(url)) {
				setDynamicLodingScreen(<SuccessScreenSkeleton />);
			} else if (/^\/curation\/[a-zA-Z0-9-]+$/.test(url)) {
				setDynamicLodingScreen(<CurationDetailsSkeleton />);
			} else if (/^\/list\/[a-zA-Z0-9-]+$/.test(url)) {
				setDynamicLodingScreen(<CurationDetailsSkeleton />);
			} else if (/^\/community\/[a-zA-Z0-9-]+$/.test(url)) {
				setDynamicLodingScreen(<UserScreenSkeleton />);
			} else if (url == "/discover") {
				setDynamicLodingScreen(<DiscoverScreenSkeleton />);
			} else if (url == "/playoff") {
				setDynamicLodingScreen(<CurateScreenSkeleton />);
			} else if (url == "/favourites") {
				setDynamicLodingScreen(<FavoriteScreenSkeleton />);
			} else if (url == "/me") {
				setDynamicLodingScreen(<UserScreenSkeleton />);
			} else {
				console.log("===============");
				console.log("Grape Listing screen");
				console.log("===============");
				setDynamicLodingScreen(<CurationListingSkeleton />);
			}
		});

		Router.events.on("routeChangeComplete", (url) => {
			setIsLoading(false);
			// setTimeout(() => {
			// 	isBack.current=false
			// }, 2000);
		});

		Router.events.on("routeChangeError", (url) => {
			setIsLoading(false);
		});
		Router.beforePopState(({ url, as, opts }) => {
			// console.log(url, as, opts);
			isBack.current = true;
			if (url === "/curation/add") {
				window.location.href = "/";
				window.history.replaceState(null, "", "/");
				return false;
			}
			return true;
		});
	}, [Router]);

	useEffect(() => {
		if (isLoading) {
			document.body.classList.add("loader");
		} else {
			document.body.classList.remove("loader");
		}
	}, [isLoading,dynamicLodingScreen]);

	const [interval, setInterval] = useState(0);

	return (
		<CacheProvider value={emotionCache}>
			<RouterScrollProvider>
				<SessionProvider session={pageProps.session}>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={lightTheme}>
							<CssBaseline />
							{!isiOS && <PushNotificationLayout />}
							<RefreshTokenHandler setInterval={setInterval} />
							<ScrollRestorationProvider isback={isBack}>
							<ScrollProvider isback={isBack}>
								<CurationListProvider>
									{/* <Layout> */}
										{isLoading ? (
											<Skeleton dynamicLodingScreen={dynamicLodingScreen}/>
										) : (
											<Component {...pageProps} />
										)}
									{/* </Layout> */}
								</CurationListProvider>
								</ScrollProvider>
							</ScrollRestorationProvider>
						</ThemeProvider>
					</StyledEngineProvider>
				</SessionProvider>
			</RouterScrollProvider>
		</CacheProvider>
	);
};

export default MyApp;

MyApp.propTypes = {
	Component: PropTypes.elementType.isRequired,
	emotionCache: PropTypes.object,
	pageProps: PropTypes.object.isRequired,
};
