import React, { useEffect, useState } from "react";
import * as firebase from "firebase/app";
import "firebase/messaging";

import { ToastContainer, toast } from "react-toastify";
import { useRouter } from "next/router";

import { getMessagingToken, onMessageListener } from "../lib/firebase";
import { useSession } from "next-auth/react";
import {
	Box,
	Button,
	Divider,
	Drawer,
	Paper,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";

function PushNotificationLayout({ children }) {
	const router = useRouter();
	const [isTokenFound, setTokenFound] = useState(false);
	const [open, setOpen] = useState(false);
	const { data: session, status } = useSession();

	//   firebaseCloudMessaging.onMessageListener().then(message => {toast(
	//     <div onClick={() => handleClickPushNotification(message?.data?.url)}>
	//       <h5>{message?.notification?.title}</h5>
	//       <h6>{message?.notification?.body}</h6>
	//     </div>,
	//     {
	//       closeOnClick: false,
	//     }
	//   );})
	useEffect(() => {
		
		if (status === "authenticated") {	
			
			setTimeout(() => {
				if (
					"Notification" in window &&
					"permission" in window.Notification
				) {
					if (
						Notification.permission === "default" &&
						!/iphone|ipad|ipod/.test(
							window.navigator.userAgent.toLowerCase() ||
								window.navigator.userAgent.indexOf("Instagram") !== -1								
						) && false
					) {
						setOpen(true);
						sessionStorage.setItem('notificationOncePerSession', true);
					}
				}
			}, 3000);

			// setToken();
		}

		// getToken().then((res) => {
		// 	let { isAuthenticated, token } = res;
		// 	if (isAuthenticated) {
		// 		setToken();
		// 	}

		// 	setIsAuthenticated(isAuthenticated);
		// });
	}, [status]);

	async function setToken() {
		try {
			const token = await getMessagingToken(session);
			if (token) {
				getMessage();
			}
		} catch (error) {
			console.log(error);
		}
	}

	// Handles the click function on the toast showing push notification
	const handleClickPushNotification = (url) => {
		router.push(url);
	};

	// Get the push notification message and triggers a toast to display it
	const getMessage = () => {
		onMessageListener().then((message) => {
			toast(
				<div
					onClick={() =>
						handleClickPushNotification(message?.data?.url)
					}
				>
					<h5>{message?.notification?.title}</h5>
					<h6>{message?.notification?.body}</h6>
				</div>,
				{
					closeOnClick: false,
				}
			);
		});
	};
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	let mobileCss=!isMobile?{
		width:480,left:"50%",transform: "translate(-50%)"
	}:{width:"100%"}

	return (
		<>
			<ToastContainer />
			<Drawer
				sx={{
					zIndex: 100001,
					marginBottom: 2,
					...mobileCss
				}}
				slotProps={{
					backdrop: {
						style: { backgroundColor: "rgba(0, 0, 0, 0.7)" },
					},
				}}
				anchor={"bottom"}
				open={open}
				// hideBackdrop={true}
				onClose={() => {}}
				PaperProps={{
					square: false,
					sx: { borderRadius: "24px 24px 0px 0px" },
				}}
				// PaperProps={sx={borderRadius: "24px 24px 0px 0px"}}
			>
				

				<Paper sx={{ padding: 3.75 }}>
					<Box>
						<Typography sx={{ paddingBottom: 2 }} variant="h3">
							Stay connected with us 💜
						</Typography>
						<Divider variant="fullWidth"></Divider>
						<Typography component={"p"} marginY={2}>
							We value your time and privacy. Enable app
							notifications for relevant updates, and rest assured
							we'll be mindful about what we send you. 📲✨
						</Typography>

						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<Button
								onClick={() => {
									setOpen(false);
								}}
								variant="text"
								sx={{ textTransform: "unset" }}
							>
								No, thanks
							</Button>
							<Button
								variant="contained"
								sx={{ textTransform: "unset" }}
								onClick={() => {
									setToken();
									setOpen(false);
								}}
							>
								Yes, sure
							</Button>
						</Stack>
					</Box>
				</Paper>
			</Drawer>
			{children}
		</>
	);
}

export default PushNotificationLayout;
