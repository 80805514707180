import React from "react";
import { Box, Card, CardContent, Grid, Skeleton, Stack } from "@mui/material";
import { cardStyle } from "../../../styles/card";

function CurationCardSkeleton() {
  const style = cardStyle;
  return (
    <Card
      sx={{
        ...style.card,
        cursor: "pointer",
        marginBottom: 2,
        boxShadow: "0px 4px 10px -6px rgba(0, 0, 0, 0.12)",
      }}
    >
      <CardContent>
       
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            {/* <Skeleton variant="text" sx={{ fontSize: "1rem" }} /> */}
          </Grid>
          <Grid item xs={5}>
            <Skeleton variant="rounded" height={115} width={115} />
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1.5} mt={1}>
          <Skeleton
            animation="wave"
            variant="circular"
            width={25}
            height={25}
          />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"90%"} />
        </Stack>
      </CardContent>
    </Card>
  );
}

export default CurationCardSkeleton;
