import {alpha} from '@mui/material'
export const cardStyle = {
  card: {
    borderColor: "#cfd8dc",  
    backgroundColor: "#fff",
    border: "1px solid #E9E7ED",
    boxShadow: "none",
    borderRadius: "12px",
  },
  instacard: {
    borderColor: "#E7F5FC",
    backgroundColor: alpha("#E7F5FC",0.3),    
    border: "1px solid #E7F5FC",  
    borderRadius: "8px",
  },
  instacardWithSwitch:{
    backgroundColor: "#fff",
    border: "1px solid #E9E7ED",
    boxShadow: "0px 4px 10px -6px rgba(0, 0, 0, 0.12)",
    borderRadius: "12px",
    
  }
};
