import Router from "next/router";
import { createContext, useEffect, useReducer, useRef, useState } from "react";


export const ScrollRestorationContext=createContext()


const ScrollRestorationProvider=({children,isback})=>{
    const [pageDetails, setPageDetails] = useState([]);
    const [back, setBack]=useState(isback)
    const scrollContainerRef = useRef(null);
    // useEffect(()=>{
    //     const localState = JSON.parse(localStorage.getItem("pageDetails"));
    //     if(localState!==null && localState.length){
    //         setPageDetails(localState)
    //     }


    // },[])
  
    // useEffect(() => {
    //     localStorage.setItem("pageDetails", JSON.stringify(pageDetails));
	// }, [pageDetails]);
    
    return <ScrollRestorationContext.Provider value={{pageDetails, setPageDetails,back, setBack, scrollContainerRef}}>
        {children}
    </ScrollRestorationContext.Provider>
}

export default ScrollRestorationProvider