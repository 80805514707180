import axios from "axios";
import { useSession } from "next-auth/react";


// import { BASE_URL, TOKEN } from "../../utils/Constants";


//add your BASE_URL to Constants file

// console.log(BASE_URL);


export const AuthHeader = () => {
	
	let headers = {  };
	if(typeof window !== undefined)
	{
		const token = localStorage.getItem("token");
		if (token) {
			headers.Authorization = "Bearer " + token;
		}
	}
    // headers.Authorization = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjcwMDgwODY4LCJpYXQiOjE2NjkyMTY4NjgsImp0aSI6ImQ4YTExZmM5MGE0MTRlZTdiOTU0ZmY2NzM2ZjVkNjMzIiwidXNlcl9pZCI6M30.nh2IF3NN_PFONW7i1qoInnydIUf0TyN7QfxwRjXz2Lc"
	
	return headers;
};

export const setHeader=(token)=>{
	let headers={}
	if(token){
		headers.Authorization = "Bearer " + token.accessToken;
		
	}
	return headers
}

const axiosInstance = axios.create({
	baseURL: process.env.BACKEND_URL,
	withCredentials: true,
	// headers: AuthHeader(),
});

axiosInstance.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axiosInstance.defaults.xsrfCookieName = "csrftoken";

export  {axiosInstance}




// Handle request process
axiosInstance.interceptors.request.use((request) => requestHandler(request));

// Handle response process
axiosInstance.interceptors.response.use(
	(response) => successHandler(response),
	(error) => errorHandler(error)
);

export const isHandlerEnabled = (config = {}) => {
	// console.log(
	// 	config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
	// );
	// return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled

	return true;
};

const requestHandler = (request) => {
	// token = await getAuthToken(request)
	// console.log(token);
	// request.headers['Authorization'] = AuthHeader();
	
    // return config;
	
	if (isHandlerEnabled(request)) {

       
		// Modify request here
		// store.dispatch(loader(true));
	}
	return request;
};

const successHandler = (response) => {
	if (isHandlerEnabled(response)) {
        // console.log('====================================');
        // console.log(`Request done successfully: ${response.config.url}`);
        // console.log('====================================');
		// Hanlde Response
		// store.dispatch(loader(false));
	}
	return response;
};

const errorHandler = async (error) => {
	const originalConfig = error.config;
	


	// if (originalConfig.url !== "/auth/signin/" && error.response) {
	// 	// Access Token was expired
	// 	if (error.response.status === 401 && !originalConfig._retry) {
	// 		originalConfig._retry = true;
	// 		let refresh=localStorage.getItem('refresh_token')
	// 		try {
	// 			const rs = await axiosInstance.post("/auth/token/refresh/", {
	// 				refresh
	// 			},{headers:{}});
	// 			console.log(rs);
	// 			let accessToken=rs.data.access
	// 			originalConfig.headers = {
	// 				...originalConfig.headers,
	// 				authorization: `Bearer ${accessToken}`,
	// 			  };
	// 			// axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
				
	// 			localStorage.setItem("token",accessToken)
				
	// 			return axiosInstance(originalConfig);
	// 		} catch (_error) {
	// 			console.log(_error);
	// 			localStorage.removeItem('token')
	// 			localStorage.removeItem('user')
	// 			localStorage.removeItem('refresh_token')
	// 			return Promise.reject(_error);
	// 		}
	// 	}
	// 	if (error.response.status === 403 && error.response.data) {
	// 		localStorage.removeItem('token')
	// 			localStorage.removeItem('user')
	// 			localStorage.removeItem('refresh_token')
	// 		return Promise.reject(error.response.data);
	// 	  }
	// }
	if (isHandlerEnabled(error.config)) {
        // console.log('====================================');
        // console.log(`Unfortunately error happened during request: `);
        // console.log(error.response)
		
	}
	return Promise.reject({ ...error });
};



// export const setHeader=(token)=>{
	
// 	axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
// 	// console.log(axiosInstance.defaults.headers.common["Authorization"]);
// }