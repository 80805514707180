import { Box, Grid, Skeleton, Stack } from "@mui/material";
import React from "react";
import ProductCardSkeleton from "../ProductCardSkeleton/ProductCardSkeleton";

function CurationDetailsSkeleton() {
	return (
		<Box
			sx={{
				padding: 2.5,
			}}
		>
			<Stack
				direction="row"
				sx={{
					display: { xs: "flex", md: "flex" },
					marginBottom: 2.5,
					justifyContent: "space-between",
				}}
			>
				<Skeleton variant="rounded" height={35} width={"40%"} />
			</Stack>
			<Grid
				container
				columnSpacing={"1px"}
				borderRadius={2}
				spacing={2}
				sx={{ overflow: "hidden", position: "relative" }}
			>
				<Grid item xs={8} padding={1.8}>
					<Skeleton variant="rounded" height={30} width={"40%"} />
					{/* <Skeleton variant="text" sx={{ fontSize: "1rem", marginTop: 2 }} /> */}
					<Stack direction="row" spacing={1.5} mt={5.5}>
						<Skeleton
							animation="wave"
							variant="circular"
							width={25}
							height={25}
						/>
						<Skeleton
							variant="text"
							sx={{ fontSize: "1rem" }}
							width={"90%"}
						/>
					</Stack>
				</Grid>
				<Grid item xs={4}>
					<Skeleton variant="rounded" height={137} width={137} />
				</Grid>
			</Grid>
			<Skeleton variant="text" sx={{ fontSize: "1rem", marginTop: 2 }} />
			<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
			<Stack direction="row" justifyContent="space-between" mt={2}>
				<Skeleton variant="rounded" height={35} width={"70%"} />
				<Skeleton variant="rounded" height={35} width={"29%"} />
			</Stack>

			<Grid container columnSpacing={1.4} rowSpacing={1} pt={5}>
				<Grid item xs={6}>
					<ProductCardSkeleton></ProductCardSkeleton>
				</Grid>
				<Grid item xs={6}>
					<ProductCardSkeleton></ProductCardSkeleton>
				</Grid>
				<Grid item xs={6}>
					<ProductCardSkeleton></ProductCardSkeleton>
				</Grid>
				<Grid item xs={6}>
					<ProductCardSkeleton></ProductCardSkeleton>
				</Grid>
			</Grid>
		</Box>
	);
}

export default CurationDetailsSkeleton;
