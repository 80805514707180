import React from "react";
import { Box, Card, CardContent, Grid, Skeleton, Stack } from "@mui/material";
import { cardStyle } from "../../../styles/card";

function ActivePlayoffSkeleton({challengeSection = false,firstCard=false}) {
  const style = cardStyle;
  return (
    <Card
      sx={{
        ...style.card,
        marginBottom: (challengeSection || firstCard) ? "30px" : 0,
        boxShadow: "none",
        position: "relative",
      }}
    >
         <CardContent>
         <Grid
          container
          columnSpacing={"1px"}
          borderRadius={2}
          sx={{ overflow: "hidden", position: "relative" }}
        >
          <Grid item xs={3}>
            <Skeleton variant="rounded" height={137} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rounded" height={137} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rounded" height={137} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rounded" height={137} />
          </Grid>
        </Grid>
       
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"50%"} />
        
        {challengeSection !==true? <>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"10%"} />
            {/* <Skeleton variant="text" sx={{ fontSize: "1rem" }}  /> */}
        </>: <></>}
       
        <Box mt={1}>
        <Skeleton variant="rounded"  height={35}  />
        </Box>
        
         </CardContent>
    </Card>
  );
}

export default ActivePlayoffSkeleton;
