import { Box, Skeleton } from "@mui/material";
import React from "react";
import { Header } from "../../../layout/header";
import CurationCardSkeleton from "../CurationCardSkeleton/CurationCardSkeleton";

function CurationListingSkeleton() {
  return (
    <Box>
     <Skeleton variant="rounded" height={56}  /> 
      <Box sx={{ margin: 2.5 }}>  
      <Skeleton variant="text" sx={{ fontSize: "3rem", marginBottom: 1.5 }} /> 
        <CurationCardSkeleton></CurationCardSkeleton>
        <CurationCardSkeleton></CurationCardSkeleton>
        <CurationCardSkeleton></CurationCardSkeleton>
        <CurationCardSkeleton></CurationCardSkeleton>
      </Box>
      
    </Box>
  );
}

export default CurationListingSkeleton;
