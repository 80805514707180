import { createContext, useState } from "react";


export const CurationListContext=createContext()

const CurationListProvider=({children})=>{
    const [curations, setCurations] = useState([]);
    const [playoff, setPlayoffs] = useState([]);
    const [page, setPage] = useState(2);
    return <CurationListContext.Provider value={[curations, setCurations,page, setPage,playoff, setPlayoffs]}>
        {children}
    </CurationListContext.Provider>
}

export default CurationListProvider