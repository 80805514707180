import React from "react";
import { Header } from "../../../layout/header";
import { Box, Skeleton } from "@mui/material";
import ActivePlayoffSkeleton from "../ActivePlayoffSkeleton/ActivePlayoffSkeleton";
import CurationCardSkeleton from "../CurationCardSkeleton/CurationCardSkeleton";
import Layout from "../../../layout/Layout";

function HomeScreenSkeleton() {
	
	return (
		<Box>
		
				<Skeleton variant="rounded" height={56} />
				<Box sx={{ margin: 2.5 }}>
					<ActivePlayoffSkeleton
						firstCard={true}
					></ActivePlayoffSkeleton>
					<Box sx={{ marginY: 2.5 }}>
						<Skeleton
							variant="rounded"
							height={35}
							width={"60%"}
							sx={{ marginX: "auto" }}
						/>
						{/* <Skeleton
            variant="text"
            sx={{ marginX: "auto", fontSize: "1rem" }}
            width={"80%"}
          /> */}
					</Box>
					<CurationCardSkeleton></CurationCardSkeleton>
					<CurationCardSkeleton></CurationCardSkeleton>
				</Box>
        </Box>
			

	);
}

export default HomeScreenSkeleton;
