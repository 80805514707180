import { Box, Card, CardContent, Skeleton } from "@mui/material";
import React from "react";
import CurationCardSkeleton from "../CurationCardSkeleton/CurationCardSkeleton";
import { cardStyle } from "../../../styles/card";
import { useTheme, useMediaQuery } from '@mui/material';


function CurationListingWithVideoSkeleton() {
    const theme = useTheme();
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('sm'));
   
    const style = cardStyle;        
  return (
    <Box>
     <Skeleton variant="rounded" height={56}  /> 
      <Box sx={{ margin: 2.5 }}>       
      <Card
			sx={{
			...style.card,
			cursor: "pointer",
			marginBottom: 2,
			boxShadow: "0px 4px 10px -6px rgba(0, 0, 0, 0.12)",
            position:"relative",
            height:!isScreenSmall ? "438px" : "318px"
			}}
		>
			<CardContent >	
				<Box sx={{ position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}} >
				<Skeleton variant="circular" width={50} height={50} />	
				</Box>		
						
			</CardContent>
	  	</Card>
        <CurationCardSkeleton></CurationCardSkeleton>
        <CurationCardSkeleton></CurationCardSkeleton>
        <CurationCardSkeleton></CurationCardSkeleton>
        <CurationCardSkeleton></CurationCardSkeleton>
      </Box>
      
    </Box>
  );
}

export default CurationListingWithVideoSkeleton;
