import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";
import ProductCardSkeleton from "../ProductCardSkeleton/ProductCardSkeleton";

function FavoriteScreenSkeleton() {
    
  return (  
  <Box>
  <Skeleton variant="rounded" height={56} />
  <Box sx={{ margin: 2.5 }}>
  
    <Grid container sx={{mt:2}} spacing={2}>
        <Grid xs={6} item>
            <ProductCardSkeleton></ProductCardSkeleton>
        </Grid>
        <Grid xs={6} item>
            <ProductCardSkeleton></ProductCardSkeleton>
        </Grid>
        <Grid xs={6} item>
            <ProductCardSkeleton></ProductCardSkeleton>
        </Grid>
        <Grid xs={6} item>
            <ProductCardSkeleton></ProductCardSkeleton>
        </Grid>
        <Grid xs={6} item>
            <ProductCardSkeleton></ProductCardSkeleton>
        </Grid>
        <Grid xs={6} item>
            <ProductCardSkeleton></ProductCardSkeleton>
        </Grid>
    </Grid>
  </Box>
  
</Box>);
}

export default FavoriteScreenSkeleton;
