import { Box, Snackbar, Stack } from "@mui/material";
import { useRouter } from "next/router";
import { createContext, useEffect, useReducer, useRef, useState } from "react";

export const ScrollContext = createContext();

const ScrollProvider = ({ children, isback }) => {
	const router = useRouter();
	const [pageDetails, setPageDetails] = useState([]);
	const [height, setHeight] = useState(60);
	const [items, setItems] = useState([]);
	const [itemCount, setItemCount] = useState(0);
	const [isTrayOpen, setisTrayOpen] = useState(false);
	const [curationCount, setCurationCount] = useState(null);
	const [page, setPage] = useState(1);
	const [back, setBack] = useState(isback);
	const scrollContainerRef = useRef(null);
	const [pageDetail, setPageDetail] = useState(null);
	const [traydetail, setTraydetail] = useState(null);
	const [tabIndex, setTabIndex] = useState("0");
	const [filters, setFilters] = useState(["grape_list", "community_list"]);
	const [flag, setFlag] = useState(true);

	useEffect(() => {
		const localState = JSON.parse(localStorage.getItem("pageDetails"));
		if (localState !== null && localState.length) {
			setPageDetails(localState);
		}
	}, []);

	useEffect(() => {}, [traydetail]);

	useEffect(() => {}, [items]);

	const handleRouteChangeStart = (url) => {
		let index = pageDetails.findLastIndex((p) => p.current === true);
		if (index < 0) {
			let scroll = window.scrollY;
			const { id, slug } = router.query;
			let pageDetail = {
				url: router.asPath,
				pathname: router.pathname,
				scrollTo: scroll,
				slug,
				items,
				page,
				itemCount,
				isTrayOpen,
				traydetail,
				tabIndex,
				filters,
			};
			setPageDetails((prev) => [...prev, pageDetail]);
		} else {
		}
	};

	const handlebeforePopState = ({ url, as, options }) => {
		let index = pageDetails.findLastIndex((p) => p.url === as);
		if (index >= 0) {
			let newPageDetails = pageDetails.map((details, i) => {
				details.current = i === index;
				return details;
			});
			setPageDetails(newPageDetails);
		}

		return true;

		// Perform any custom logic or tasks
	};
	const handleRouteChangeComplete = (url) => {
		console.log("handleRouteChangeComplete");
		let index = pageDetails.findLastIndex((p) => p.current === true);
		setPageDetail(null);
		if (index >= 0) {
			let pd = pageDetails[index];
			setPageDetail(pd);
			setTimeout(() => {
				setPageDetails(pageDetails.splice(0, index));
			}, 3000);
			// setPageDetails(pageDetails.splice(0, index));
		} else {
			setPageDetail(null);
			setisTrayOpen(false);
			setItems([]);
			setPage(1);
			setItemCount(0);
			setTraydetail(null);
			setTabIndex("0");
			setFilters(["grape_list", "community_list"]);

			// setHistory([...history, url]);
		}

		// Perform any custom logic or tasks
	};

	useEffect(() => {
		setTimeout(() => {
			if (router.asPath === "/") {
				setPageDetails([]);
			}
		}, 1000);

		router.events.on("routeChangeStart", handleRouteChangeStart);
		router.beforePopState(handlebeforePopState);
		router.events.on("routeChangeComplete", handleRouteChangeComplete);
		return () => {
			router.events.off("routeChangeStart", handleRouteChangeStart);
			router.events.off("routeChangeComplete", handleRouteChangeComplete);
			router.beforePopState(null);
		};
	}, [
		router,
		pageDetail,
		items,
		page,
		isTrayOpen,
		traydetail,
		tabIndex,
		filters,
	]);

	useEffect(() => {
		localStorage.setItem("pageDetails", JSON.stringify(pageDetails));
	}, [pageDetails]);

	const updatePaginationItems = (
		updatedItems,
		currentPage,
		total,
		tab = "0",
		filters = ["grape_list", "community_list"]
	) => {
		setItems(updatedItems);
		setPage(currentPage);
		setItemCount(total);
		setTabIndex(tab);
		setFilters(filters);
	};
	const updateTrayItems = (curationCount) => {
		setisTrayOpen(true);
		setTraydetail({ ...traydetail, curationCount });
	};

	const [scrollY, setScrollY] = useState(0);

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	useEffect(() => {
		// Add event listener when component mounts
		window.addEventListener("scroll", handleScroll);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []); //

	return (
		<ScrollContext.Provider
			value={{
				pageDetail,
				setPageDetail,
				scrollContainerRef,
				updatePaginationItems,
				updateTrayItems,
				setTabIndex,
			}}
		>
			<Box>
				<Box
					sx={{
						overflow: "auto",
						position: "fixed",
						display: { xs: "none", md: "block" },
						top: 0,
						right: 0,
						left: 0,
						bottom: 0,
						// height:"100%",
						background:
							"linear-gradient(180deg, rgba(252, 229, 246, 0),  #E7F5FC  50%, #E7F5FC  50%, rgba(252, 229, 246, 0))",
						zIndex: -1,
					}}
				></Box>
				{/* <Snackbar
					// anchorOrigin={{ "top", "center" }}
					open={true}
					onClose={() => {}}
					message={`Scroll Y: ${scrollY}`}
					// key={vertical + horizontal}
				/> */}
				<Stack
					direction="row"
					spacing={2}
					justifyContent="center"
					// ref={scrollContainerRef}
				>
					{/* Rest of your component */}
					<Box
						sx={{
							width: { xs: "100%", md: 480 },
							// mx: "auto",
							background: "white",
							boxShadow:
								"0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
						}}
					>
						{children}
					</Box>
				</Stack>
			</Box>

			{/* <Box
				sx={{
					overflow: "auto",
					position: "fixed",
					display: { xs: "none", md: "block" },
					top: 0,
					right: 0,
					left: 0,
					bottom: 0,
					// height:"100%",
					background:
						"linear-gradient(180deg, rgba(252, 229, 246, 0),  #E7F5FC  50%, #E7F5FC  50%, rgba(252, 229, 246, 0))",
					zIndex: -1,
				}}
			></Box>
			<Stack
				direction="row"
				spacing={2}
				justifyContent="center"
				// ref={scrollContainerRef}
			>
				<Box
					ref={scrollContainerRef}
					sx={{
						height: flag===true?'auto':`calc(100vh - ${height}px)`,
						position: flag===true?"relative":"fixed",
						overflow: "scroll",
						"&::-webkit-scrollbar": {
							display: "none",
						},
						msOverflowStyle: "none",
						scrollbarWidth: "none",
						background: "white",
						width: { xs: "100%", md: 480 },
					}}
				>
					{children}
				</Box>
			</Stack> */}
		</ScrollContext.Provider>
	);
};

export default ScrollProvider;
