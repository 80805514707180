import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";
import CurationCardSkeleton from "../CurationCardSkeleton/CurationCardSkeleton";

function UserScreenSkeleton() {
  return (
    
      <Box
        
      >
        <Skeleton variant="rectangle" height={60} />

        <Box sx={{ padding: 2.5 , display:"flex", justifyContent:"flex-start", alignItems:"center" }}>
          <Skeleton variant="circular" width={80} height={80} />
          <Box ml={2}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", mt: 1 }}
            width={180}
            height={23}
          />
          </Box>
         
        </Box>
        <Box marginY={3} paddingX={2.5}>
            <Stack direction="row" justifyContent="space-between">
           <Skeleton variant="rounded" height={40} width="68%" />
           <Skeleton variant="rounded" height={40} width="18%"/>
          <Skeleton variant="rounded" height={40} width="10%"/>
            </Stack>
            <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", mt: 1 }}
           
          />
            <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", mt: 1 }}
           
          /><Box mt={6.8}>
            <CurationCardSkeleton></CurationCardSkeleton>
          </Box>
          <Box mt={2.5}>
            <CurationCardSkeleton></CurationCardSkeleton>
          </Box>
          
        </Box>
      </Box>
    
  );
}

export default UserScreenSkeleton;
