import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";
import ActivePlayoffSkeleton from "../ActivePlayoffSkeleton/ActivePlayoffSkeleton";
import CurationCardSkeleton from "../CurationCardSkeleton/CurationCardSkeleton";
import ProductCardSkeleton from "../ProductCardSkeleton/ProductCardSkeleton";
import InstaCardSkeleton from "../InstaCardSkeleton/InstaCardSkeleton";

function CurationTitleSkeleton() {
  return (
    <Box>
      <Skeleton variant="rounded" height={50} />
      <Box sx={{ margin: 2.5 }}>
        <Skeleton variant="text" />
        <Skeleton
            variant="rounded"
            height={35}
            width={"60%"}
            sx={{mt:2}}
           
          />
        <Skeleton variant="text"  width={"20%"} sx={{mt:5.5}}/>
        <Grid container mt={.1} columnSpacing={1.8} rowSpacing={2}>
            <Grid item xs={6}>
                <ProductCardSkeleton></ProductCardSkeleton>
            </Grid>
            <Grid item xs={6}>
                <ProductCardSkeleton></ProductCardSkeleton>
            </Grid>
        </Grid>
        <Box mt={2}>
        <InstaCardSkeleton></InstaCardSkeleton>
        </Box>
        
        
      </Box>
    </Box>
  );
}

export default CurationTitleSkeleton;
