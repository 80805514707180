import React, { useEffect, useState } from 'react'
import { LoadingSpinner } from './LoadingSpinner'
import HomeScreenSkeleton from '../Skeletons/Screens/HomeScreenSkeleton';
import PlayoffProductListingSkeleton from '../Skeletons/Screens/PlayoffProductListingSkeleton';
import CurationListingSkeleton from '../Skeletons/Screens/CurationListingSkeleton';
import PlayoffDetailsSkeleton from '../Skeletons/Screens/PlayoffDetailsSkeleton';
import CurationTitleSkeleton from '../Skeletons/Screens/CurationTitleSkeleton';
import SuccessScreenSkeleton from '../Skeletons/Screens/SuccessScreenSkeleton';
import CurationDetailsSkeleton from '../Skeletons/Screens/CurationDetailsSkeleton';
import UserScreenSkeleton from '../Skeletons/Screens/UserScreenSkeleton';
import DiscoverScreenSkeleton from '../Skeletons/Screens/DiscoverScreenSkeleton';
import CurateScreenSkeleton from '../Skeletons/Screens/CurateScreenSkeleton';
import FavoriteScreenSkeleton from '../Skeletons/Screens/FavoriteScreenSkeleton';
import Router from 'next/router';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { BottomNavbar } from '../../layout/footer';

export default function Skeleton({dynamicLodingScreen}) {
    
    const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
    <Box
    // pb={10}
    sx={{
        backgroundColor: "white",
        width: isMobile ? "100%" : "480px",
        position: "fixed",
        top: 0,
    
        left: "50%",
        transform: "translate(-50%)",
        bottom: 8,
        overflow: "hidden",
    }}
>
    {dynamicLodingScreen}
    
    </Box>
    <BottomNavbar/>
    </>
  )
}
