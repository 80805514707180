import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";

import ProductCardSkeleton from "../ProductCardSkeleton/ProductCardSkeleton";

function PlayoffProductListingSkeleton() {
	return (
		<Box
			sx={{
				paddingX: 2.5,
			}}
		>
			<Box marginY={3}>
				<Skeleton variant="rounded" height={35} width={"40%"} />
			</Box>

			<Grid container spacing={2.5}>
				<Grid item xs={6}>
					<ProductCardSkeleton></ProductCardSkeleton>
				</Grid>
				<Grid item xs={6}>
					<ProductCardSkeleton></ProductCardSkeleton>
				</Grid>
				<Grid item xs={6}>
					<ProductCardSkeleton></ProductCardSkeleton>
				</Grid>
				<Grid item xs={6}>
					<ProductCardSkeleton></ProductCardSkeleton>
				</Grid>
			</Grid>
		</Box>
	);
}

export default PlayoffProductListingSkeleton;
