import { AuthHeader, axiosInstance, setHeader } from "../utility/AxoisConfig";
import { saveToken } from "../utility/UserToken";
// axiosInstance.header=AuthHeader()

export const sendOTP = async (data) => {
	let res = await axiosInstance.post("auth/v1/send-otp", { ...data });
	if (res.status == 200) {
		let { data } = res;
		return data;
	}
};

export const VerifyOTP = async (data) => {
	let res = await axiosInstance.post("auth/v1/verify-otp", { ...data });
	if (res.status == 200) {
		let { data } = res;
		console.log(data.user);
		return data;
	}
};

export const createUser = async (data) => {
	let res = await axiosInstance.post("/me/communityuser/", data);
	if (res.status == 200) {
		// saveToken(res.data)
		// axiosInstance.defaults.headers.Authorization = "Bearer " + data.token
	}

	return res;
};

export const getTokenByPhone = async (data) => {
	let res = await axiosInstance.post("auth/token/", data);
	if (res.status == 200) {
		//  saveToken(res.data)
		// return res
		// axiosInstance.defaults.headers.Authorization = "Bearer " + res.data.access
	}
	return res;
};

export const updateUser = async (data, id, token) => {
	let headers = setHeader(token);
	let res = await axiosInstance.put("/me/update_profile/" + id + "/", data, {
		headers: headers,
	});
	return res;
};

export const CheckTokenUser = async () => {
	let headers = AuthHeader();
	let res = await axiosInstance.get("/me/", { headers: headers });
	return res;
};

export const getUser = async (token) => {
	let headers = setHeader(token);
	let res = await axiosInstance.get("/me/", { headers: headers });
	return res;
};

export const getMyCurations = async (token, page = 1) => {
	let headers = setHeader(token);
	let res = await axiosInstance.get(`me/curations`, {
		params: { page_size: 5, page: page },
		headers: headers,
	});

	return res;
};

export const verifyToken = async () => {
	let token = localStorage.getItem("token");
	try {
		let res = await axiosInstance.post("/auth/token/verify/", {
			token: token,
		});
		let headers = AuthHeader();
		let res1 = await axiosInstance.get("/me/", { headers: headers });
		// console.log('====================================');
		// console.log(token);
		// console.log('====================================');
			(token);
		return { valid: true };
	} catch (_error) {
		return { valid: false };
	}
};

export const registerMessagingToken = async (token,session) => {
	try {
		let headers = setHeader(session);
		let res = await axiosInstance.post(
			"/auth/devices/",
			{
				registration_id: token,
				type: "web",
			},
			{ headers: headers }
		);
		console.log("====================================");
		console.log(res);
		console.log("====================================");

		return { valid: true };
	} catch (_error) {
		console.log("====================================");
		console.log(_error);
		console.log("====================================");
		return { valid: false };
	}
};
