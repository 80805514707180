import {
	Avatar,
	Badge,
	BottomNavigation,
	BottomNavigationAction,
	Paper,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import homeBlue from "../assets/icon/footerIcons/home2.svg";
import homeBlack from "../assets/icon/footerIcons/home1.svg";
import discoverBlack from "../assets/icon/footerIcons/discover1.svg";
import discoverBlue from "../assets/icon/footerIcons/discover2.svg";
import playoffBlack from "../assets/icon/footerIcons/playoff1.svg";
import playoffBlue from "../assets/icon/footerIcons/playoff2.svg";
import heartBlack from "../assets/icon/footerIcons/heart1.svg";
import heartBlue from "../assets/icon/footerIcons/heart2.svg";
import { useRouter } from "next/router";

let menuArray = [
	{
		label: "Home",
		images: [homeBlack, homeBlue],
		to: "/",
		class: "",
	},
	{
		label: "Discover",
		images: [discoverBlack, discoverBlue],
		to: "/discover",
		class: "discover",
	},
	{
		label: "Curate",
		images: [playoffBlack, playoffBlue],
		to: "/playoff",
		class: "playoff",
	},
	{
		label: "Favorites",
		images: [heartBlack, heartBlue],
		to: "/favourites",
		class: "",
	},
];

function getPageIndex(route) {
	switch (route) {
		case "/":
			return 0;
		case "/discover":
			return 1;
		case "/playoff":
			return 2;
		case "/favourites":
			return 3;
		default:
			return 0;
	}
}

export const BottomNavbar = () => {
	const router = useRouter();
	let route = router.pathname;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [value, setValue] = useState(getPageIndex(route));

	
	const handleNavigation=(e,newValue)=>{
		setValue(newValue);
		const {to} =menuArray[newValue]
		router.push(to)
	}

	return (
		<Paper
			sx={{
				position: "fixed",
				bottom: 0,
				width:isMobile?"100%":"480px"
				// left: 0,
				// right: 0,
				// borderRadius: "8px 8px 0 0",
				// overflow: "hidden",
				// zIndex:100000
			}}
			// elevation={3}
		>
			<BottomNavigation
				showLabels
				value={value}
				sx={{
					height: "63px",
				}}
				onChange={handleNavigation}
			>
				{menuArray.map((item, i) => {
					return (
						<BottomNavigationAction
							label={item.label}
							icon={
								i==1?
								<Badge color="primary" badgeContent={"New"}  anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								  }} 
								  variant="string"
								  sx={{
									"&	.MuiBadge-badge":{
										right: "-11px",
										top: "5px",
										fontSize: "10px",
										padding: "7px"
									}
								  }}
								 >
								<Avatar
									sx={{ width: 20, height: 20 }}
									variant="square"
									src={
										value === i
											? item.images[1]
											: item.images[0]
									}
								></Avatar>
								</Badge>
								:
								<Avatar
								sx={{ width: 20, height: 20 }}
								variant="square"
								src={
									value === i
										? item.images[1]
										: item.images[0]
								}
							></Avatar>
							}
							className={item.class}
							key={i}
							to={item.to}
							sx={{
								paddingTop: "3px",
								"& .MuiBottomNavigationAction-label": {
									marginTop: "5px",
								},
							}}
						></BottomNavigationAction>
					);
				})}
			</BottomNavigation>
		</Paper>
	);
};
