import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import animationData from "assets/animation/loadingSpinner2.json";


export const LoadingSpinner = () => {
	const options = {
		animationData: animationData,
		loop: true,
	};
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	let mobileCss=!isMobile?{
		width:480,left:"50%",transform: "translate(-50%)"
	}:{width:"100%",left:0}

	return (
		<Box
			sx={{
				position: "fixed",
				top: "0",
				...mobileCss,
				backgroundColor: "white",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				zIndex: 9999,
				height: "100%",
				// width: "100%",
			}}
		>
			<Lottie
				options={options}
				isClickToPauseDisabled={true}
				// height={100}
			></Lottie>
		</Box>
	);
};
