import { Box, Grid, Skeleton,} from "@mui/material";
import React from "react";
import ProductCardSkeleton from "../ProductCardSkeleton/ProductCardSkeleton";
import Layout from "../../../layout/Layout";

function DiscoverScreenSkeleton() {
	
	return (
		
			<Box
				sx={{
					 // Adjust the bottom value to accommodate the navbar height
				}}
			>
				<Skeleton variant="rounded" height={56} />
				<Box sx={{ margin: 2.5 }}>
					<Skeleton variant="text" />
					<Grid
						container
						columnSpacing={2}
						rowSpacing={"24px"}
						mt={1}
						justifyContent="center"
						alignItems="center"
					>
						{Array.from(new Array(12)).map((item, index) => {
							return (
								<Grid
									item
									xs={4}
									key={`skeletelAvatar+${index}`}
								>
									<Skeleton
										variant="circular"
										width={98}
										height={98}
									/>
								</Grid>
							);
						})}
					</Grid>
					<Box mt={6} mb={3}>
						<Skeleton variant="text" />
						<Grid container sx={{ mt: 2 }} spacing={2}>
							<Grid xs={6} item>
								<ProductCardSkeleton></ProductCardSkeleton>
							</Grid>
							<Grid xs={6} item>
								<ProductCardSkeleton></ProductCardSkeleton>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
	);
}

export default DiscoverScreenSkeleton;
