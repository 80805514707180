import React from "react";
import { cardStyle } from "../../../styles/card";
import { Box, Card, CardContent, Skeleton } from "@mui/material";

function ProductCardSkeleton() {
  const style = cardStyle;
  return (
    <Card
      sx={{
        ...style.card,
        marginBottom: 2,
        boxShadow: "0px 4px 10px -6px rgba(0, 0, 0, 0.12)",
      }}
    >
      <CardContent sx={{ padding: 0 }}>
        <Skeleton height={216} variant="rounded"></Skeleton>
        <Box paddingX={2} mt={1}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }}></Skeleton>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }}></Skeleton>
          <Skeleton height={40} sx={{mt:1}} variant="rounded"></Skeleton>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ProductCardSkeleton;
