import { getApps, initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import {
	getMessaging,
	getToken,
	isSupported as messagingIsSupported,
	onMessage,
} from "firebase/messaging";
import localforage from "localforage";
import { registerMessagingToken, updateUser } from "../service/auth";

const firebaseConfig = {
	apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
	measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}



const app = initializeApp(firebaseConfig);

const analytics = isSupported().then(yes => yes ? getAnalytics(app) : null);
const messaging = (async () => {
	try {
		const isSupportedBrowser = await messagingIsSupported();
		if (isSupportedBrowser) {
			return getMessaging(app);
		}
		// console.log("Firebase not supported this browser");
		return null;
	} catch (err) {
		console.log(err);
		return null;
	}
})();

const isTokenSentToServer= async()=> {
	let res=await localforage.getItem("sentToServer") === "1";
	
	return false
}

const sendTokenToServer = async(currentToken,session) =>{
	let isTokenSent= await isTokenSentToServer()
	// console.log(isTokenSent);
	if (!isTokenSent) {
		let res = await registerMessagingToken(currentToken,session)
		await updateUser({allow_notification:true},session.user.id,session)
		if(res.valid){
			setTokenSentToServer(true);
		}else{
			setTokenSentToServer(false);
		}
	} else {
		console.log('====================================');
		console.log(currentToken);
		console.log('====================================');
		console.log(
			"Token already sent to server so won't send it again " +
				"unless it changes"
		);
	}
}

function setTokenSentToServer(sent) {
  if (sent) {
	localforage.setItem("sentToServer", "1");
  } else {
	localforage.setItem("sentToServer", "0");
  }
}



export const onMessageListener = async () =>
	new Promise((resolve) =>
		(async () => {
			const messagingResolve = await messaging;
			onMessage(messagingResolve, (payload) => {
				console.log("On message: ", messaging, payload);
				resolve(payload);
			});
		})()
	);

export const getMessagingToken = async (session) => {
	const messagingResolve = await messaging;
	const tokenInLocalForage = await localforage.getItem("fcm_token");
	if (tokenInLocalForage !== null) {
		sendTokenToServer(tokenInLocalForage,session);
		return tokenInLocalForage;
	}
	
		
	try {
		const status = await Notification.requestPermission();
		if (status && status === "granted") {
			// Get new token from Firebase
			localforage.removeItem("fcm_token")
			localforage.removeItem('sentToServer')
			const fcm_token = await getToken(messagingResolve, {
				vapidKey:process.env.NEXT_PUBLIC_FIREBASE_VAILD_KEY
			});

			// Set token in our local storage
			if (fcm_token) {
				localforage.setItem("fcm_token", fcm_token);
				sendTokenToServer(fcm_token,session);
				
				return fcm_token;
			}
		}
	} catch (error) {
		
		return null;
	}
};


export { app,analytics };