import { Box, Grid, Skeleton, Stack } from "@mui/material";
import React from "react";

function PlayoffDetailsSkeleton() {
  return (
    <Box
      sx={{
        padding: 2.5,
      }}
    >
      <Stack
        direction="row"
        sx={{
          display: { xs: "flex", md: "flex" },
          marginBottom: 2.5,
          // alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Skeleton variant="rounded" height={35} width={"40%"} />
        <Box>
          <Skeleton variant="circular" width={40} height={40} />
        </Box>
      </Stack>
      <Grid
        container
        columnSpacing={"1px"}
        borderRadius={2}
        sx={{ overflow: "hidden", position: "relative" }}
      >
        <Grid item xs={3}>
          <Skeleton variant="rounded" height={137} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton variant="rounded" height={137} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton variant="rounded" height={137} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton variant="rounded" height={137} />
        </Grid>
      </Grid>
      <Skeleton variant="text" sx={{ fontSize: "1rem", marginTop: 2 }} />
      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Skeleton variant="rounded" height={35} width={"45%"} />
        <Skeleton variant="rounded" height={35} width={"45%"} />
      </Stack>
      <Skeleton variant="text" sx={{ fontSize: "1rem", marginTop: 2 }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="rounded" height={500} sx={{ mt: 3 }} />
    </Box>
  );
}

export default PlayoffDetailsSkeleton;
