
import { useState } from "react";
import {  verifyToken } from "../service/auth";
import { setHeader } from "./AxoisConfig";

export default function UserToken() {
    
    const getToken = () => {
        const tokenString = localStorage.getItem("token");
        const userToken = tokenString;
        return userToken;
    };

    const saveToken = userToken => {
        localStorage.setItem('token', userToken.access);
        localStorage.setItem('refresh_token', userToken.refresh);
        localStorage.setItem('user', JSON.stringify(userToken.user));
        setToken(userToken);
      };
    const [token, setToken] = useState(getToken);

    return {
        setToken: saveToken,
        token
      }
};

export const getToken = async () => {
    let tokenString = null;
    let isAuthenticated=false
    if (tokenString!==null){
      let checkToken= await verifyToken()
      if(!checkToken.valid){
        
        isAuthenticated=false
      }
      else{
        isAuthenticated=true
      }
      
    }
    const userToken = {isAuthenticated:isAuthenticated,token:tokenString} ;
    return userToken;
};

export const saveToken = userToken => {
  localStorage.setItem('token', userToken.access);
  localStorage.setItem('refresh_token', userToken.refresh);
  localStorage.setItem('user', JSON.stringify(userToken.user));
  setHeader(userToken.access)
  // setToken(userToken);
};

export const getUser=()=>{
    return JSON.parse(localStorage.getItem('user'))
}

