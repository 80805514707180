import { Box, Skeleton } from "@mui/material";
import React from "react";
import ActivePlayoffSkeleton from "../ActivePlayoffSkeleton/ActivePlayoffSkeleton";


function CurateScreenSkeleton() {
  
	return (
		<Box
			
			
		>
			<Skeleton variant="rounded" height={56} />
			<Box sx={{ margin: 2.5 }}>
				<ActivePlayoffSkeleton firstCard={true}></ActivePlayoffSkeleton>
				<Box sx={{ marginY: 2.5 }}>
					<ActivePlayoffSkeleton
						challengeSection={true}
					></ActivePlayoffSkeleton>
				</Box>
				<Box sx={{ marginY: 2.5 }}>
					<ActivePlayoffSkeleton
						challengeSection={true}
					></ActivePlayoffSkeleton>
				</Box>
				<Box sx={{ marginY: 2.5 }}>
					<ActivePlayoffSkeleton
						challengeSection={true}
					></ActivePlayoffSkeleton>
				</Box>
			</Box>
		</Box>
	);
}

export default CurateScreenSkeleton;
