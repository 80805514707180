import {
	Box,
	Grid,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";

import { Fragment, useContext, useEffect } from "react";
import { ScrollRestorationContext } from "../context/ScrollRestorationContext";

export default function Layout({ children }) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const { scrollContainerRef } = useContext(ScrollRestorationContext);

	return (
		<Fragment>
			{isMobile ? (
				<>
					<Box

						sx={{
							overflow: "auto",
							position: "fixed",
							top: 0,
							right: 0,
							left: 0,
							bottom: 0,
							// height:"100%",
							// zIndex:-1
						}}
					>
						{children}
					</Box>
				</>
			) : (
				<>
					
						<Stack
							direction="row"
							spacing={2}
							justifyContent="center"
							sx={
								{
									// overflow:"scroll",
									// height: "100%",
								}
							}
						>
							<Box
								// ref={scrollContainerRef}
								sx={{
									// position: "fixed",
									// height: "100vh",
									// scrollbarWidth: "none",
									// overflow: "scroll",
									// "&::-webkit-scrollbar": {
									// 	display: "none",
									// },
									// msOverflowStyle: "none" /* IE and Edge */,
									// scrollbarWidth: "none" /* Firefox */,
									// background: "white",
									width: 480,
									boxShadow:
										" 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
								}}
							>
								{children}
							</Box>
						</Stack>
				</>
			)}
		</Fragment>
	);
}
