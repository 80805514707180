import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import { getToken, getUser } from "../utility/UserToken";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

export const Header = () => {
	const [anchorElUser, setAnchorElUser] = useState(null);
	const [displayToolTip, setDisplayToolTip] = useState(false);
	const [showProfile, setShowProfile] = useState(false);
	const [user, setUser] = useState(null);
	const router = useRouter();
	const { data: session,status } = useSession();
  

	useEffect(() => {
    if(status === "authenticated")
		{
			setShowProfile(true);
			setUser(session.user);
		}
	}, [status]);

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
		// setDisplayToolTip(false)
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
		// setDisplayToolTip(true)
	};
	const styles = {
		customColor: {
			backgroundColor: "white",
			boxShadow: "0px 2px 6px rgba(16, 2, 30, 0.09)",
		},
	};

	return (
		<AppBar position="static" sx={styles.customColor}>
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Typography
						pl={2}
						variant="h5"
						noWrap
						component="a"
						sx={{
							mr: 2,
							// display: { xs: "flex", md: "none" },
							flexGrow: 1,
							textAlign: "center",
							textTransform: "uppercase",
							fontFamily: "'Montserrat', sans-serif;",
							letterSpacing: ".3rem",
							color: "black",
							textDecoration: "none",
						}}
					>
						<span
							className="grapeLogo"
							onClick={() => {
								router.push(`/`);
							}}
						>
							Grape
						</span>
					</Typography>
					{showProfile && (
						<Box
							sx={{ flexGrow: 0, position: "absolute", right: 0 }}
						>
							<IconButton href={`/me`} sx={{ p: 0 }}>
								<Avatar alt={""} src={user.image?.file} />
							</IconButton>
						</Box>
					)}
				</Toolbar>
			</Container>
		</AppBar>
	);
};
