import { createTheme } from '@mui/material/styles';


const lightTheme = createTheme({
	typography: {
		fontFamily: "'DM Sans',sans-serif",
		h1: {
			fontWeight: 700,
			fontSize: "26px",
			lineHeight: "28px",
		},
		h2: {
			fontWeight: 700,
			fontSize: "24px",
			lineHeight: "26px",
		},
		h2_new:{
			fontWeight: 700,
			fontSize: "22px",
			lineHeight: "26px",
		},
		h3: {
			fontWeight: 700,
			fontSize: "20px",
			lineHeight: "24px",
		},
		h4: {
			fontWeight: 700,
			fontSize: "18px",
			lineHeight: "24px",
		},
		body1: {
			fontWeight: 700,
			fontSize: "16px",
			lineHeight: "22px",
		},
		body2: {
			fontWeight: 500,
			fontSize: "14px",
			lineHeight: "18px",
		},
		body2_light:{
			fontWeight: 400,
			fontSize: "14px",
			lineHeight: "18px",
			color:"rgba(0, 0, 0, 0.6)"
		},
		smallText: {
			fontWeight: 500,
			fontSize: "12px",
			lineHeight: "16px",
			fontFamily: "'DM Sans',sans-serif",
		},
		smallText2: {
			fontWeight: 700,
			fontSize: "10px",
			lineHeight: "12px",
			letterSpacing: "1px",
			fontFamily: "'DM Sans',sans-serif",
		},
		button: {
			fontWeight: 700,
			fontSize: 12,
			lineHeight: 1.88,
			letterSpacing: "0.5px",
			textTransform: "uppercase",
			borderRadius: "8px",
		},
		atag: {
			fontFamily: "Inter",
			fontStyle: "normal",
			fontHight: 700,
			fontSize: "14px",
			lineHeight: "18px",
			color: "#9650FA",
		},
		newHeading: {
			fontSize: "22px",
			lineHeight: "26px",
			fontWeight: "700",
			textTransform: "capitalize",
			letterSpacing: "-0.3px",
		},
	},

	palette: {
        mode:"light",
		background: {
			default: "#fff",
		},
		background2: {
			main: "#f5f5f5",
		},

		primary: {
			main: "#9650FA",
			contrastText: "#fff",
		},
		secondary: {
			main: "#000000",
			// contrastText: "#fff",
		},
		whatsAppColor: {
			main: "#31CA68",
			contrastText: "#fff",
		},
		fabWhite: {
			main: "#fff",
		},
		shopNowButton: {
			main: "#F5F1F6",
			contrastText: "#9650FA",
		},
		grayColor: {
			main: "#E9E7ED",
		},
		button: {
			// fontWeight: 700,
			// fontSize: 12,
			// lineHeight: 1.88,
			// letterSpacing: "0.5px",
			// textTransform: "uppercase",
			borderRadius: "8px",
		},
		batchColor1: {
			main: "#FCE5F6",
		},
		batchColor2: {
			main: "#EDDDFE",
		},
		batchColor3: {
			main: "#FDF5EB",
		},
		redBackground:{
			main:"#E62728"
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: () => ({
					textTransform: "uppercase",
					borderRadius: "8px",
				}),
			},
		},

		primary: {
			main: "#9650FA",
			contrastText: "#fff",
		},
		secondary: {
			main: "#000000",
			// contrastText: "#fff",
		},
		whatsAppColor: {
			main: "#31CA68",
			contrastText: "#fff",
		},
		fabWhite: {
			main: "#fff",
		},
		shopNowButton: {
			main: "#F5F1F6",
			contrastText: "#9650FA",
		},
		grayColor: {
			main: "#E9E7ED",
		},
		batchColor1: {
			main: "#FCE5F6",
		},
		batchColor2: {
			main: "#EDDDFE",
		},
		batchColor3: {
			main: "#FDF5EB",
		},
	},
	MuiTextField: {
		styleOverrides: {
			root: () => ({
				border: "1px solid #E9E7ED",
				boxShadow: "0px 4px 10px -6px rgba(0, 0, 0, 0.12)",
				borderRadius: " 8px",
			}),
		},
	},
});


export default lightTheme;