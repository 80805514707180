import { createContext, useContext, useState } from "react";
import AppContext from "./AppContext";

export const CurationLayout = ({ children }) => {
	const [curations, setCurations] = useState([]);
	return (
		<AppContext.Provider
			value={{ curations: curations, setCurations: setCurations }}
		>
			{children}
		</AppContext.Provider>
	);
};
