import { Box, Card, CardContent, Grid, Skeleton, Stack } from "@mui/material";
import React from "react";
import CurationCardSkeleton from "../CurationCardSkeleton/CurationCardSkeleton";
import { cardStyle } from "../../../styles/card";

function SuccessScreenSkeleton() {
  const style = cardStyle;
  return (
    
      <Box
        sx={{
          
          padding: 2.5,
  
        }}
      >
        <Box
          marginX="auto"
          sx={{
            mt: 2.2,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Skeleton variant="circular" width={70} height={70} />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", mt: 1 }}
            width="80%"
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", mt: 1 }}
            width="30%"
          />
        </Box>
        <Box mt={1.8}>
          <Card
            sx={{
              ...style.card,
              cursor: "pointer",
              marginBottom: 2,
              boxShadow: "0px 4px 10px -6px rgba(0, 0, 0, 0.12)",
            }}
          >
            <CardContent>
              <Grid container>
                <Grid item xs={8}>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem", mt: 1 }}
                    width="30%"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Box marginLeft="auto" sx={{ width: "fit-content" }}>
                    <Skeleton variant="rounded" height={92} width={92} />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Skeleton variant="rounded" height={43} />
        <Box mt={3.2}>
          <Card
            sx={{
              ...style.card,
              cursor: "pointer",
              marginBottom: 2,
              boxShadow: "0px 4px 10px -6px rgba(0, 0, 0, 0.12)",
            }}
          >
            <CardContent>
              <Grid container>
                <Grid item xs={8}>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem", mt: 1 }}
                    width="80%"
                  />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem", mt: 1 }}
                    width="80%"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Box marginLeft="auto" sx={{ width: "fit-content" }}>
                    <Skeleton variant="rounded" height={92} width={92} />
                  </Box>
                </Grid>
              </Grid>
              <Stack direction="row" mt={5} justifyContent="space-between">
                <Skeleton variant="rounded" height={40} width="60%" />
                <Skeleton variant="rounded" height={40} width="18%" />
                <Skeleton variant="rounded" height={40} width="18%" />
              </Stack>
            </CardContent>
          </Card>
        </Box>
        <Box marginY={4}>
          <Skeleton variant="text" sx={{ fontSize: "2.5rem", mt: 1 }} />
        </Box>
        <CurationCardSkeleton></CurationCardSkeleton>
      </Box>
    
  );
}

export default SuccessScreenSkeleton;
